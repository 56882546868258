import { ReactNode, RefObject, createContext, useMemo, useRef } from 'react'
import { useRecoilValue } from 'recoil'
import useSentenceBoxCreatorHotkeys from './useSentenceBoxCreatorHotkeys'
import useMultiSelectCommandHotkeys from './useMultiSelectCommandHotkeys'
import useMutationHotkeys from './useMutationHotkeys'
import useSelectHotkeys from './useSelectHotkeys'
// eslint-disable-next-line import/no-cycle
import useTogglePlayHotkeys from './useTogglePlayHotkeys'
import { isPlayingState } from '../../recoil/audiocontroller/selectors'
// eslint-disable-next-line import/no-cycle
import useTrackMoveHotkeys from './useTrackMoveHotkeys'
import useSeekHotkeys from './useSeekHotkeys'
import usePreventDefaultHotkeys from './usePreventDefaultHotkeys'

function PlayingHotkeys() {
  useTrackMoveHotkeys()
  useSeekHotkeys()

  return null
}

function NonPlayingHotkeys() {
  useSentenceBoxCreatorHotkeys()
  useMultiSelectCommandHotkeys()
  useMutationHotkeys()
  useSelectHotkeys()

  return null
}

function AlwaysHotkeys() {
  useTogglePlayHotkeys()
  usePreventDefaultHotkeys()

  return null
}

function Hotkeys() {
  const isPlaying = useRecoilValue(isPlayingState)

  return (
    <>
      <AlwaysHotkeys />
      {isPlaying ? <PlayingHotkeys /> : <NonPlayingHotkeys />}
    </>
  )
}

export const HotkeysContext = createContext({
  playButtonRef: undefined as RefObject<HTMLDivElement | null> | undefined,
  prevButtonRef: undefined as RefObject<HTMLDivElement | null> | undefined,
  nextButtonRef: undefined as RefObject<HTMLDivElement | null> | undefined,
})

export default function AivatarHotKeysRoot({ children }: { children: ReactNode }) {
  const playButtonRef = useRef<HTMLDivElement>(null)
  const prevButtonRef = useRef<HTMLDivElement>(null)
  const nextButtonRef = useRef<HTMLDivElement>(null)
  const contextValues = useMemo(
    () => ({
      playButtonRef,
      prevButtonRef,
      nextButtonRef,
    }),
    [],
  )

  return (
    <HotkeysContext.Provider value={contextValues}>
      <Hotkeys />
      {children}
    </HotkeysContext.Provider>
  )
}
