import { AnchorHTMLAttributes, ReactNode } from 'react'
import { useCurrentWorkspaceContracted } from '../../../hooks/queries'

type Props = {
  href?: AnchorHTMLAttributes<HTMLAnchorElement>['href']
  target?: AnchorHTMLAttributes<HTMLAnchorElement>['target']
  disabled?: boolean
  children: ReactNode
}

export default function PlanLink({ href, children, target, disabled }: Props) {
  const contracted = useCurrentWorkspaceContracted()

  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a
      className="block w-[180px] rounded-[28px] border-[2px] border-[var(--border,var(--color-main-1))] py-[8px] text-[12px] text-[var(--text,var(--color-main-1))] hover:bg-[var(--hover-bg,var(--color-main-1))] hover:text-[var(--hover-text,var(--color-gray-7))]"
      href={contracted ? undefined : href}
      target={target}
      style={{
        ['--border' as any]: contracted || disabled ? 'var(--color-gray-3)' : undefined,
        ['--text' as any]: contracted || disabled ? 'var(--color-gray-3)' : undefined,
        ['--hover-bg' as any]: contracted || disabled ? 'var(--color-gray-7)' : undefined,
        ['--hover-text' as any]: contracted || disabled ? 'var(--color-gray-3)' : undefined,
      }}
    >
      {children}
    </a>
  )
}
