import { useEffect, useMemo, useState } from 'react'
import { motion } from 'framer-motion'

import { useRecoilState } from 'recoil'
import { useTranslation } from 'react-i18next'
import useAivatarDidMount from '../../../../../../hooks/useAivatarDidMount'
import WarningIcon from '../../../../../../assets/images/global_icon-warning-red.png'
import BackgroundColorPicker from '../BackgroundColorPicker'
import NormalItem, { BackgroundSelectContentNormalItemProps, BackgroundType } from './NormalItem'
import { projectValueState } from '../../../../../../recoil/project/selectors'

// import { ReactComponent as CustomImage } from '../../../../../../assets/images/videooption_image-custom.svg';

type Props = {
  isOpen: boolean
}

function BackgroundSelectContent({ isOpen }: Props) {
  const { t } = useTranslation('project-option')
  const [backgroundColor, setBackgroundColor] = useRecoilState(projectValueState({ key: 'backgroundColor' }))
  const [selectedBackgroundType, setSelectedBackgroundType] = useState<BackgroundType>('CHROMA')

  /** Life Cycle */
  useAivatarDidMount(() => {}, [])
  useEffect(() => {
    switch (backgroundColor) {
      case '008000':
        setSelectedBackgroundType('CHROMA')
        break
      case null:
        setSelectedBackgroundType('NOTHING')
        break
      default:
        setSelectedBackgroundType('CUSTOM')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /** Event */
  const onClickItem: BackgroundSelectContentNormalItemProps['onClickItem'] = (backgroundType) => {
    switch (backgroundType) {
      case 'CHROMA':
        setBackgroundColor('008000')
        break
      case 'NOTHING':
        setBackgroundColor(null)
        break
      default:
        setBackgroundColor('008000')
    }
    setSelectedBackgroundType(backgroundType)
  }

  /** Displays */

  const WraningComponent = useMemo(() => {
    if (selectedBackgroundType !== 'NOTHING') return null
    return (
      <div className="mt-[22px] flex items-center justify-center rounded-full border-[0.5px] border-[#F05E5E] px-[15px] py-[5px]">
        <img className="mr-[5px] h-[20px] w-[20px]" src={WarningIcon} alt="warning" />
        <p className="text-[12px] text-[#F05E5E]">{t('내보내기 시 WebM 확장자만 선택 가능합니다.')}</p>
      </div>
    )
  }, [selectedBackgroundType, t])

  const ColorPickerComponent = useMemo(() => {
    if (selectedBackgroundType !== 'CUSTOM') return null
    return <BackgroundColorPicker />
  }, [selectedBackgroundType])

  return (
    <motion.div
      // className="flex w-full flex-col items-center"
      animate={{
        height: isOpen ? 'auto' : 0,
        marginTop: isOpen ? '32px' : '0px',
        opacity: isOpen ? 1 : 0,
      }}
      style={{ overflow: isOpen ? 'visible' : 'hidden' }}
    >
      {/* <BackgroundColorPicker /> */}
      <div className="flex w-full items-center justify-between rounded-[24px] bg-[#F5F7FE] px-[18px] pb-[11px] pt-[24px]">
        <NormalItem
          title={t('크로마키')}
          type="CHROMA"
          selectedBackgroundType={selectedBackgroundType}
          onClickItem={onClickItem}
        />
        <NormalItem
          title={t('투명')}
          type="NOTHING"
          selectedBackgroundType={selectedBackgroundType}
          onClickItem={onClickItem}
        />

        <NormalItem
          title={t('커스텀')}
          type="CUSTOM"
          selectedBackgroundType={selectedBackgroundType}
          onClickItem={onClickItem}
          color={backgroundColor}
        />
      </div>
      {WraningComponent}
      {ColorPickerComponent}
    </motion.div>
  )
}

export default BackgroundSelectContent
