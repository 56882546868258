import { useEffect } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import RecentProjectListSection from '../../components/Dashboard/Home/RecentProjectListSection'
import RecentExportListSection from '../../components/Dashboard/Home/RecentExportListSection'

import AivatarClient from '../../services/AivatarClient'
import useDialog from '../../hooks/useDialog'
import SubscriptionPopup from '../../components/popups/SubscriptionPopup'
import { LocalStorage, SessionStorage } from '../../utils/storage'
import useAuth from '../../hooks/useAuth'
import { authQueries } from '../../services/auth'
import TopBanner from './TopBanner'

export default function Home() {
  const queryClient = useQueryClient()

  const { user } = useAuth()

  const dialog = useDialog()

  const sendSurveyData = (fullText) => {
    AivatarClient()
      .post(`/users/submit-survey`, {
        fullText,
      })
      .then(() => {
        queryClient.invalidateQueries({ queryKey: authQueries.me.queryKey })
      })
  }

  useEffect(() => {
    if (
      !LocalStorage.notShownPopupList.includes('SUBSCRIPTION') &&
      !SessionStorage.closedPopupList.includes('SUBSCRIPTION')
    ) {
      dialog.open(<SubscriptionPopup />)
    }

    if (!user.surveyCompleted) {
      // eslint-disable-next-line no-undef
      Tally.openPopup('wgAAyD', {
        onSubmit: (payload) => {
          const fullText = JSON.stringify(payload)
          sendSurveyData(fullText)
          // Tally.closePopup('wbWX76');
        },
        customFormUrl: 'https://tally.aivatar.ai/wgAAyD',
        layout: 'modal',
        overlay: true,
        width: 600,
        autoClose: 1000,
      })
    }

    const handleClickOutside = (event) => {
      if (event.target.classList.contains('tally-overlay')) {
        event.stopPropagation()
      }
    }
    window.addEventListener('click', handleClickOutside, true)

    return () => {
      window.removeEventListener('click', handleClickOutside, true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {/* todo - replace link to & img src */}
      {/* <Link to={"https://share.hsforms.com/1sV5S9RryRw2PlmagnuiNmAniurs"} target="_blank"> */}

      <TopBanner />
      {/* </Link> */}
      {/* <iframe src="https://tally.so/embed/wbWX76?alignLeft=1&hideTitle=1&transparentBackground=1&dynamicHeight=1" width="100%" height="200" frameBorder="0" marginHeight="0" marginWidth="0" title="Let us know about you!"></iframe> */}
      {/* <button data-tally-open="wbWX76" data-tally-emoji-text="👋" data-tally-emoji-animation="wave">Click me</button> */}
      <RecentProjectListSection className="mt-[50px]" />
      <RecentExportListSection />
    </>
  )
}
