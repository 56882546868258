import { useRecoilValue } from 'recoil'
import { useMemo } from 'react'
import { currentSelectedDressState } from '../../../../../../recoil/workspace/selectors'

export default function Item({ dress, itemWidth, itemHeight, index, onClickItem }) {
  const currentSelectedDress = useRecoilValue(currentSelectedDressState)

  const isSelected = useMemo(() => dress?.seqId === currentSelectedDress?.seqId, [dress, currentSelectedDress])

  const { thumbnailSrc } = dress
  return (
    <button
      type="button"
      style={{ height: itemHeight, width: itemWidth }}
      className={`mb-[9px] bg-[#F5F7FE] ${isSelected && 'main_bd shadow_selected border-[2px]'} ${
        index % 2 === 0 && 'mr-[6px]'
      } hover:bg-main-3 rounded-[24px]`}
      onClick={() => {
        onClickItem(dress?.seqId)
      }}
    >
      <img className="h-full w-full object-contain" src={thumbnailSrc} alt="dress" />
    </button>
  )
}
