import { useTranslation } from 'react-i18next'
import useURI from '../../../hooks/useURI'
import useAuth from '../../../hooks/useAuth'

type Props = {
  significant?: boolean
}

function ContactLink({ significant }: Props) {
  const { user } = useAuth()
  const { uri } = useURI()

  const { t } = useTranslation('plan-info-modal')

  return (
    <a
      className="block w-[180px] rounded-[28px] border-[2px] border-[var(--border,var(--color-main-1))] py-[8px] text-[12px] text-[var(--text,var(--color-main-1))] hover:bg-[var(--hover-bg,var(--color-main-1))] hover:text-[var(--hover-text,var(--color-gray-7))]"
      href={`${uri['contact-us']}?email=${user?.email}`}
      target="_blank"
      rel="noreferrer"
    >
      {significant ? t('세일즈팀에 문의') : t('문의하기')}
    </a>
  )
}

export default ContactLink
