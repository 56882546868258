import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import clsx from 'clsx'
import { Navigation, Pagination, Autoplay } from 'swiper/modules'
import Banner1 from '../../assets/images/1290 X 120_01.png'
import Banner2 from '../../assets/images/1290 X 120_02.png'
import Banner3 from '../../assets/images/1290 X 120_03.png'
// import { useTranslation } from 'react-i18next'
// import BannerImageEn from '../../assets/images/home-banner-1-en.png'
// import BannerImageKo from '../../assets/images/home-banner-1-ko.png'
// import SubscriptionPlanDialog from '../../components/SubscriptionPlan/SubscriptionPlanDialog'
// import useDialog from '../../hooks/useDialog'

export default function TopBanner() {
  // const { i18n } = useTranslation()
  // const dialog = useDialog()

  const slideStyle = clsx('')

  return (
    <Swiper
      modules={[Navigation, Pagination, Autoplay]}
      navigation
      pagination
      autoplay={{
        delay: 5000,
        disableOnInteraction: false,
      }}
      loop
      style={{ ['--swiper-theme-color' as any]: '#fff', ['--swiper-navigation-size' as any]: '32px', borderRadius: 8 }}
      // className="[&_.swiper-pagination]:absolute [&_.swiper-pagination]:bottom-0 [&_.swiper-pagination]:z-[999] [&_.swiper-pagination]:h-[30px] [&_.swiper-pagination]:w-full"
    >
      <SwiperSlide className={slideStyle}>
        <a
          href="https://www.aivatar.ai?utm_source=banner&utm_medium=website&utm_campaign=workspace-banner-2411A&utm_content=cta-home"
          target="_blank noreferrer"
          aria-label="banner"
        >
          <img src={Banner1} alt="" />
        </a>
      </SwiperSlide>
      <SwiperSlide className={slideStyle}>
        <a
          href="https://www.aivatar.ai/contactus?utm_source=banner&utm_medium=website&utm_campaign=workspace-banner-2411B&utm_content=cta-contact"
          target="_blank noreferrer"
          aria-label="banner"
        >
          <img src={Banner2} alt="" />
        </a>
      </SwiperSlide>
      <SwiperSlide className={slideStyle}>
        <a
          href="https://www.aipark.ai/reference?utm_source=banner&utm_medium=website&utm_campaign=workspace-banner-2411C&utm_content=cta-reference"
          target="_blank noreferrer"
          aria-label="banner"
        >
          <img src={Banner3} alt="" />
        </a>
      </SwiperSlide>
    </Swiper>
    // <button type="button" className="w-full" onClick={() => dialog.open(<SubscriptionPlanDialog expanded />)}>
    //   <img className="bg-main-3 h-[120px] w-full object-cover" src={Banner} alt="home-banner" />
    // </button>
  )
}
