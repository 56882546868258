import { useRef, useState, useMemo } from 'react'
import ReactPlayer from 'react-player'

import { twMerge } from 'tailwind-merge'
import useListenVoice from '../../../../../services/workspace/useListenVoice'
import useAivatarDidMount from '../../../../../hooks/useAivatarDidMount'
import IconLoading from '../../../../../components/Loading/IconLoading'

function VoicePlay({ voice, className }) {
  const player = useRef(null)
  const [playing, setPlaying] = useState(false)
  const {
    isLoadingListenVoice,
    // isCallSuccessListenVoice,
    // url,
    initListenVoice,
    // fetchListenVoice,
  } = useListenVoice()
  //! url이 초기에 할당된다면 player 렌더링 속도 저하묌
  const [source, setSource] = useState()

  const isPlaying = useMemo(() => isLoadingListenVoice || playing, [isLoadingListenVoice, playing])

  useAivatarDidMount(() => {
    initListenVoice()
    setPlaying(false)
  }, [voice])

  const onClickPlay = async (e) => {
    if (isPlaying) return
    e.preventDefault()
    e.stopPropagation()
    if (!voice.id) return
    setSource(voice.sampleSrc)
    setPlaying(true)

    // if (url) {
    //   setPlaying(true);
    // } else {
    //   await fetchListenVoice({ voiceId: voice.id });
    //   setPlaying(true);
    // }
  }

  const onEnded = () => {
    setPlaying(false)
  }

  return (
    <button type="button" className="z-10" onClick={onClickPlay}>
      <ReactPlayer
        id="VoicePlayer"
        ref={player}
        playing={playing}
        className="react-player"
        style={{
          display: 'none',
        }}
        url={source}
        autoPlay
        controls
        playsinline
        progressInterval={1000}
        width={0}
        height={0}
        config={{
          file: {
            // forceAudio: true,
            attributes: {
              // autoPlay: false,
              // controlsList: "nodownload",
              crossOrigin: 'anonymous',
              onContextMenu: (e) => e.preventDefault(),
            },
          },
        }}
        // onReady={onReady}
        // onSeek={onSeek}
        // onProgress={onProgress}
        onEnded={onEnded}
        // onError={onError}
        // onDuration={onDuration}
        // onBuffer={onBuffer}
      />
      <button
        className={twMerge(
          'border-main-1 bg-gray-7 hover:border-main-1 hover:bg-main-1 group relative flex h-[30px] w-[30px] min-w-[30px] items-center justify-center rounded-full border-[2px] text-white',
          className,
        )}
      >
        <div className="mr-[-2px] h-[14px] w-[11px] overflow-hidden">
          <div className="border-main-1 group-hover:border-l-gray-7 border-x-[11px] border-y-[7px] border-b-transparent border-r-transparent border-t-transparent" />
        </div>
        {isPlaying && <IconLoading size="20px" className="group-hover:text-gray-7" />}
      </button>
    </button>
  )
}

export default VoicePlay
