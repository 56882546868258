import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useCallback, useEffect } from 'react'
import { authQueries, loginUser } from '../services/auth'
import { LoggedUser } from '../@types/common'
import LOCALSTORAGE_KEY from '../constants/localstorage-key'

const LOCALSTORAGE_KEY_USER = LOCALSTORAGE_KEY.USER

export default function useAuth() {
  const queryClient = useQueryClient()

  const {
    data: user,
    error,
    isLoading,
    refetch,
  } = useQuery({
    ...authQueries.me,
    enabled: !!localStorage.getItem(LOCALSTORAGE_KEY_USER), // localStorage에 유저 정보가 있을 때만 실행
    // staleTime: 60000, // 1분 동안 캐시된 데이터를 사용
    initialData: () => {
      const localStorageUser = localStorage.getItem(LOCALSTORAGE_KEY_USER)
      if (!localStorageUser) {
        return null
      }

      return JSON.parse(localStorageUser) as LoggedUser
    },
  })

  const mutation = useMutation({
    mutationFn: loginUser,
    onSuccess: (data) => {
      localStorage.setItem(LOCALSTORAGE_KEY_USER, JSON.stringify(data))
      queryClient.setQueryData(authQueries.me.queryKey, data)
      refetch() // 로그인 후 유저 정보 새로고침
    },
  })

  const login = async ({ email, password }: { email: string; password: string }) => {
    try {
      const data = await mutation.mutateAsync({ email, password })
      return data
    } catch (loginError) {
      console.error(loginError)
      throw loginError
    }
  }

  const logout = () => {
    localStorage.removeItem(LOCALSTORAGE_KEY_USER)
    queryClient.removeQueries({ queryKey: authQueries.me.queryKey })
  }

  const setOAuthUser = useCallback(
    (OAuthUser: LoggedUser) => {
      localStorage.setItem(LOCALSTORAGE_KEY_USER, JSON.stringify(OAuthUser))
      queryClient.setQueryData(authQueries.me.queryKey, OAuthUser)
    },
    [queryClient],
  )

  useEffect(() => {
    const localStorageUser = localStorage.getItem(LOCALSTORAGE_KEY_USER)
    if (localStorageUser) {
      queryClient.setQueryData(authQueries.me.queryKey, JSON.parse(localStorageUser))
    }
  }, [queryClient])

  useEffect(() => {
    if (user) localStorage.setItem(LOCALSTORAGE_KEY_USER, JSON.stringify(user))
  }, [user])

  return { user, login, logout, isLoading, error, setOAuthUser }
}
