import { useTranslation } from 'react-i18next'
import { ExclamationCircleIcon } from '@heroicons/react/20/solid'
import useURI from '../../../hooks/useURI'
import useAuth from '../../../hooks/useAuth'

function ContractButton({ className = '' }) {
  const { t } = useTranslation('dashboard')
  const { user } = useAuth()
  const { uri } = useURI()

  const onClickHelp = () => {
    // window.open(`${uri['contact-us']}?email=${user.email}`)
    window.open(
      `${uri['bug-report']}?email_address=${user.email}&workspace_url=${window.location.href}&browser_info=${window.navigator.userAgent}`,
    )
  }
  return (
    <button className={`mx-[27px] flex items-center ${className}`} onClick={onClickHelp}>
      <ExclamationCircleIcon className="fill-point-2 -ml-[3px] mr-[4px] h-[28px] w-[28px]" />

      <span className="text-point-2 text-[14px] font-medium">{t('버그 리포트')}</span>
    </button>
  )
}

export default ContractButton
