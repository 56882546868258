import { useTranslation } from 'react-i18next'
import { ArrowUpRightIcon } from '@heroicons/react/20/solid'
import useURI from '../../../hooks/useURI'

function GuideButton({ className = '' }) {
  const { t } = useTranslation('dashboard')
  const { uri } = useURI()

  return (
    <a href={uri.guide} target="_blank noreferrer" className={`mx-[27px] flex items-center ${className}`}>
      <span className="text-point-2 text-[14px] font-medium">{t('AiVATAR 가이드 영상')}</span>
      <ArrowUpRightIcon className="fill-point-2 ml-[3px] h-[28px] w-[28px]" />
    </a>
  )
}

export default GuideButton
