/* eslint-disable n/no-process-env */

export default Object.freeze({
  NODE_ENV: process.env.NODE_ENV,

  API_HOST: process.env.REACT_APP_API_HOST || 'https://dapi.aivatar.ai',
  WEBSOCKET_HOST: process.env.REACT_APP_WEBSOCKET_HOST || 'wss://dapi.aivatar.ai',

  // 결제 관련 환경변수
  IMP: process.env.REACT_APP_IMP || 'imp85016062',
  PG: process.env.REACT_APP_PG || 'tosspayments.iamporttest_4',
  PAY_NAME: process.env.REACT_APP_PAY_NAME || '최초인증결제',
})
