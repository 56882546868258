import Banner from './Banner'
import Divider from './Divider'
import NavLinks from './NavLinks'
import HelpButton from './HelpButton'
import CreditStatus from './CreditStatus'
import Menu from './Menu'
import NewProjectButton from './NewProjectButton'
import ContactButton from './ContactButton'
import UpdateNoteButton from './UpdateNoteButton'
import GuideButton from './GuideButton'

function NavBar() {
  //! TSK-1748 요구사항에 의해 임시제거
  // const couponLinkParam = {
  //   id: "workspace",
  //   label: DASHBOARD.COUPON,
  //   to: `/workspace/${workspaceId}/config`,
  //   Icon: HomeIcon
  // }

  return (
    <div className="bg-main-4 flex h-full w-[270px] shrink-0 flex-col pb-[35px]">
      <Menu />
      <Divider />
      <NewProjectButton className="mt-[30px]" />
      <NavLinks />
      <Banner className="mx-[20px]" />
      {/* //! TSK-1748 요구사항에 의해 임시제거 */}
      {/* <div className="relative pl-[20px] py-[30px]">
        <AivatarNavLink
          label={couponLinkParam.label}
          to={couponLinkParam.to}
          Icon={couponLinkParam.Icon}
          index={999}
          currentIndex={999}
        />
      </div> */}

      <div className="mt-auto flex flex-col gap-[20px]">
        <GuideButton />
        <Divider />
        <UpdateNoteButton />
        <HelpButton />
        <ContactButton />
      </div>
      <Divider className="mt-[20px]" />
      <CreditStatus />

      {/* <HelpButton className="mt-auto" />
      <ContactButton className="mt-[25px]" />
      <Divider className="mt-[20px]" />
      <CreditStatus /> */}
    </div>
  )
}

export default NavBar
