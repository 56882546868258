import { useContext, useEffect, useMemo, useState } from 'react'
import { useRecoilValue } from 'recoil'
import SentenceBoxConnector from '../../../providers/SentenceBoxConnector'
import useAivatarDidMount from '../../../hooks/useAivatarDidMount'

import { sentenceBoxIdsState } from '../../../recoil/texteditor/editor/atoms'
import {
  editorValueState,
  isEmptySentenceBoxState,
  isMultiSelectModeEnabledState,
  isValidFocusedBoxState,
  isValidSetenceBoxInEditorState,
} from '../../../recoil/texteditor/editor/selectors'
import SentenceBoxRoot from './SentenceBoxRoot'
import useEditorCallbacks from '../../../recoil/texteditor/editor/useEditorCallbacks'
import TextEditorHeader from './TextEditorHeader'
import FirstIntervalAudioManager from './FirstIntervalAudioManager'
import useSentenceBoxCallbacks from '../../../recoil/texteditor/sentenceBox/useSentenceBoxCallbacks'
import AivatarBackdrop from '../../../components/AivatarBackdrop'
import { isPlayingState } from '../../../recoil/audiocontroller/selectors'
import FirstAddParagraphButton from './SentenceBox/FirstAddParagraphButton'
import useAivatarModal from '../../../hooks/useAivatarModal'
import SentenceBoxErrorModal from './SentenceBoxErrorModal'
import { HotkeysContext } from '../../../providers/AivatarHotKeysRoot'

function TextEditor() {
  const { playButtonRef } = useContext(HotkeysContext)

  /** Recoils */
  const sentenceBoxIds = useRecoilValue(sentenceBoxIdsState)
  const isEmptySentenceBox = useRecoilValue(isEmptySentenceBoxState)
  const { addPlaceHolderSentenceBoxAtFirstIndex } = useSentenceBoxCallbacks()

  const isValidSetenceBoxInEditor = useRecoilValue(isValidSetenceBoxInEditorState)
  const isMultiSelectModeEnabled = useRecoilValue(isMultiSelectModeEnabledState)

  const isPlaying = useRecoilValue(isPlayingState)

  const { resetSelectAndFocusedAtClickEditor } = useEditorCallbacks()

  /** Modal */
  const onClickClose = () => {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    closeSentenceBoxErrorModal()
  }
  const {
    modalComponent: SentenceBoxErrorModalComponent,
    openModal: openSentenceBoxErrorModal,
    closeModal: closeSentenceBoxErrorModal,
  } = useAivatarModal({
    children: <SentenceBoxErrorModal type onClickClose={onClickClose} />,
    closeOnDocumentClick: false,
    contentStyle: {
      marginTop: '3%',
      padding: 0,
      width: 'fit-content',
      backgroundColor: 'transparent',
      // pointerEvents: "none"
    },
    overlayStyle: {
      // pointerEvents: "none",
      backgroundColor: 'transparent',
    },
  })

  /** Life Cycle */
  useAivatarDidMount(() => {
    if (isEmptySentenceBox) {
      addPlaceHolderSentenceBoxAtFirstIndex()
    }
  }, [isEmptySentenceBox])

  const [errorModalInitialized, setErrorModalInitialized] = useState(false)
  const focusedBoxId = useRecoilValue(editorValueState({ key: 'focusedBoxId' }))
  const isValidFocusedBox = useRecoilValue(isValidFocusedBoxState)

  useEffect(() => {
    if (!errorModalInitialized && !isValidSetenceBoxInEditor) {
      openSentenceBoxErrorModal()
      setErrorModalInitialized(true)
    }

    if (!isValidFocusedBox) {
      openSentenceBoxErrorModal()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValidSetenceBoxInEditor, focusedBoxId])

  /** Event Handler */
  const onClickEditorBack = () => {
    if (!isMultiSelectModeEnabled) {
      resetSelectAndFocusedAtClickEditor()
    }
  }

  /** Methods */

  /** Validations */
  const isAvailable = useMemo(() => !isPlaying, [isPlaying])

  const handleBackdropClicked = () => {
    if (!isAvailable) {
      playButtonRef?.current?.click()
    }
  }

  return (
    <section className="relative h-full w-full rounded-[24px] bg-[#FAFAFB] px-[30px] pb-[15px]">
      {SentenceBoxErrorModalComponent}
      <AivatarBackdrop
        isFullScreen={false}
        open={!isAvailable}
        backgroundColor="#ffffff00"
        onClick={handleBackdropClicked}
      />
      <TextEditorHeader />
      <FirstIntervalAudioManager />
      <div className="min-h-[10px]" />
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
      <div
        className="aivatar_scroll_wrapper inline-block h-[calc(100%-60px)] w-full overflow-hidden overflow-y-auto whitespace-pre-wrap break-all bg-[#FAFAFB]"
        onClick={onClickEditorBack}
      >
        <div className="h-[2px] w-full bg-transparent" />
        <FirstAddParagraphButton />
        {sentenceBoxIds.map((sentenceBoxId, index) => (
          <SentenceBoxConnector.Provider key={`SentenceBoxRoot-${index + 1}`}>
            <SentenceBoxRoot id={sentenceBoxId} index={index} />
          </SentenceBoxConnector.Provider>
        ))}
      </div>
    </section>
  )
}

export default TextEditor
